import React, { useState, useEffect, useCallback } from "react";
import Table from "../components/Table.tsx";
import ApiService from "../Class/ApiService.ts";
import Cookies from "js-cookie";
import Modal from "../components/Modal";

// API 응답 인터페이스 정의
interface ApiResponse<T = any> {
  point: number;
  profile: any[];
  posts: any[];
  code: number;
  msg: string;
  token?: string;
  data?: T;
}

const MypagePoint: React.FC = () => {
  const [point, setPoint] = useState<number>(0);
  const [pointData, setPointData] = useState<any[]>([]);
  const [userdata, setUserData] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 모달 열기/닫기 핸들러
  const toggleModal = (isOpen: boolean) => setIsModalOpen(isOpen);

  // API 호출 로직 분리: 공통 함수로 코드 중복 제거
  const fetchData = useCallback(async (endpoint: string, setter: any) => {
    try {
      const token = Cookies.get("token") || "";
      const apiService = new ApiService(process.env.REACT_APP_POTEN_URL || "");
      const response = await apiService.get<ApiResponse>(
        endpoint,
        undefined,
        token
      );

      if (response.data.code === 200) {
        setter(response.data);
      } else {
        handleUpdateDataResponse(response.data);
      }
    } catch (error) {
      console.error(`${endpoint} 조회 중 오류 발생:`, error);
    }
  }, []);

  // 데이터를 업데이트하는 핸들러
  const handleUpdateDataResponse = (responseData: ApiResponse) => {
    alert(responseData.msg);
    if (responseData.code === 300) {
      window.location.replace("/");
    }
  };

  // 포인트와 유저 데이터를 불러오는 함수
  const loadUserData = useCallback(() => {
    fetchData("/users/who_am_i/", (data) => setUserData(data));
    fetchData("/users/my_point_log/", (data) => {
      setPoint(data.point);
      setPointData(data.posts);
    });
  }, [fetchData]);

  useEffect(() => {
    loadUserData();
  }, [loadUserData]);

  return (
    <div className="mx-auto w-4/5 flex flex-col m-20 lg:gap-16">
      <UserPointSection
        point={point}
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
        userdata={userdata}
      />
      <PointHistorySection pointData={pointData} />
    </div>
  );
};

// 포인트 섹션 컴포넌트 분리
const UserPointSection: React.FC<{
  point: number;
  toggleModal: (isOpen: boolean) => void;
  isModalOpen: boolean;
  userdata: any[];
}> = ({ point, toggleModal, isModalOpen, userdata }) => (
  <div className="flex flex-col justify-start items-center gap-x-4 sm:flex-row">
    <p className="p-3 text-2xl font-semibold sm:text-4xl">
      현재 포인트 : {point.toLocaleString("en-US")}
    </p>
    <div className="flex flex-row justify-start items-center gap-x-2">
      <ActionButton label="출금 신청" />
      <ActionButton label="" onClick={() => toggleModal(true)} />
    </div>
    <Modal
      userdata={userdata}
      isOpen={isModalOpen}
      closeModal={() => toggleModal(false)}
    />
  </div>
);

// 포인트 기록 섹션 컴포넌트 분리
const PointHistorySection: React.FC<{ pointData: any[] }> = ({ pointData }) => (
  <section className="grid grid-cols-1 py-8 gap-4">
    <Table type={"point"} bodyData={pointData} />
  </section>
);

// 액션 버튼 컴포넌트 분리
const ActionButton: React.FC<{ label: string; onClick?: () => void }> = ({
  label,
  onClick,
}) => (
  <button
    onClick={onClick}
    className="w-40 p-1.5 text-xs text-center text-gray-50 bg-brand rounded-full border-2 border-brand hover:opacity-90 sm:text-lg"
  >
    {label}
  </button>
);

export default MypagePoint;
